/* eslint-disable react-hooks/exhaustive-deps */
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faCode,
  faHighlighter,
  faImage,
  faItalic,
  faLink,
  faList,
  faListOl,
  faParagraph,
  faQuoteLeft,
  faRotateLeft,
  faRotateRight,
  faUnderline
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Code from '@tiptap/extension-code';
import Document from '@tiptap/extension-document';
import Dropcursor from '@tiptap/extension-dropcursor';
import Hightlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link'; 
import OrderedList from '@tiptap/extension-ordered-list';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorProvider, useCurrentEditor, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import "./index.css";

const MenuBar = (props) => {
  const {
    setBody,
    currentlyEditing,
    setCurrentlyEditing,
    openLinkPrompt,
    setOpenLinkPrompt,
    url, setUrl,
    updateEditorContent,
    setUpdateEditorContent
  } = props;
  const [linkUrl, setLinkUrl] = useState();
  const { editor } = useCurrentEditor();
  const emailContent = editor.getHTML();
  if (currentlyEditing === "subject") {
    setCurrentlyEditing("body");
  }
  setBody(emailContent);

  const addImageFromUrl = useCallback(() => {
    const url = window.prompt('Please enter the image URL:');
    if (!url) return;
  
    try {
      new URL(url);
      editor.chain().focus().setImage({ src: url }).run();
    } catch (error) {
      alert('The URL provided is not valid. Please enter a valid URL.');
    }
  }, [editor]);

  useEffect(() => {
    if (updateEditorContent && editor) {
      const position = editor.state.selection.$cursor.pos + 2;
      const inputValue = updateEditorContent?.originalBody;
      const newValue = inputValue.slice(0, position) + updateEditorContent?.value + inputValue.slice(position);
      editor.commands.setContent(newValue);
      setUpdateEditorContent(null);
      return
    }
  }, [updateEditorContent])

  if (!editor) {
    return null
  }

  return (
    <div className="editor-menu">
      {/* text history */}
      <FontAwesomeIcon
        onClick={() => editor.commands.undo()} icon={faRotateLeft} />
      <FontAwesomeIcon
        onClick={() => editor.commands.redo()} icon={faRotateRight} />
      {/* text formatting */}
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive("blockquote") ? "is-active" : ""} icon={faQuoteLeft} />
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? "is-active" : ""} icon={faBold} />
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={editor.isActive('underline') ? "is-active" : ""} icon={faUnderline} />
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().toggleHighlight().run()}
        className={editor.isActive('highlight') ? "is-active" : ''} icon={faHighlighter} />
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? "is-active" : ''} icon={faItalic} />
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={editor.isActive('paragraph') ? "is-active" : ''} icon={faParagraph} />
      {/* hyperlink */}
      <FontAwesomeIcon onClick={() => {
        let url = prompt("Enter url link: ");
        if (url) {
          editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
        }
      }}
        icon={faLink} />
      <FontAwesomeIcon
        onClick={() => editor.commands.toggleCode()} icon={faCode} />
      {/* heading */}
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()} className={`${editor.isActive('heading', { level: 1 }) ? 'is-active heading' : ''} heading`}>H1</div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()} className={`${editor.isActive('heading', { level: 2 }) ? 'is-active heading' : ''} heading`}>H2</div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()} className={`${editor.isActive('heading', { level: 3 }) ? 'is-active heading' : ''} heading`}>H3</div>
      <div
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()} className={`${editor.isActive('heading', { level: 4 }) ? 'is-active heading' : ''} heading`}>H4</div>
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().setTextAlign('left').run()} icon={faAlignLeft} />
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().setTextAlign('center').run()} icon={faAlignCenter} />
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().setTextAlign('right').run()} icon={faAlignRight} />
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().setTextAlign('justify').run()} icon={faAlignJustify} />
      {/* List  */}
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().toggleBulletList().run()} icon={faList} />
      <FontAwesomeIcon
        onClick={() => editor.chain().focus().toggleOrderedList().run()} icon={faListOl} />
      <FontAwesomeIcon
        onClick={addImageFromUrl} icon={faImage} />
    </div>
  )
}

const Editor = (props) => {
  const {
    value,
    setValue,
    currentlyEditing,
    setCurrentlyEditing,
    updateEditorContent,
    setUpdateEditorContent
  } = props;

  const bodyRef = useRef();
  const [openLinkPrompt, setOpenLinkPrompt] = useState(false);
  const [urlLink, setUrlLink] = useState();

  const extensions = [Document, StarterKit, Hightlight, Underline, Typography,
    Dropcursor,
    OrderedList,
    Code,
    Image,
    Link.configure({
      HTMLAttributes: {
        class: "text-blue-500 hover:underline",
        target: "_blank",
        rel: "noopener noreferrer",
      },
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
      alignments: ['left', 'right', 'center', 'justify']
    })];
  const [content] = useState(value);

  return (
    <div className="editor-container">
      <EditorProvider
        ref={bodyRef}
        slotBefore={<MenuBar
          body={value}
          setBody={setValue}
          currentlyEditing={currentlyEditing}
          setCurrentlyEditing={setCurrentlyEditing}
          openLinkPrompt={openLinkPrompt}
          setOpenLinkPrompt={setOpenLinkPrompt}
          url={urlLink}
          setUrl={setUrlLink}
          updateEditorContent={updateEditorContent}
          setUpdateEditorContent={setUpdateEditorContent}
        />}
        extensions={extensions}
        content={content}
      ></EditorProvider>
    </div>
  )
}

export default Editor
